import React, { useRef, useState } from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { styled, th, Grid, Row, Col, Box, Typography } from '@smooth-ui/core-sc';

//* Accordion
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/minimal-example.css';

//* Slick
import Slider from 'react-slick';
import { NextArrow, PreviousArrow } from 'components/Slider/Arrows';

// Components
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Button from 'components/Button';
import Link from 'components/Link';
import Map from 'components/Map';
import Contact from 'components/Forms/Contact';

// Helpers
import { numberWithCommas } from 'utils/helpers';
import theme from 'utils/theme';

export const query = graphql`
  query PropertyTemplateQuery($slug: String!) {
    property: sanityProperty(slug: { current: { eq: $slug } }) {
      title
      shortDescription
      description {
        children {
          text
        }
      }
      transaction
      slug {
        current
      }
      status
      price
      type {
        id
        title
      }
      street
      number
      location {
        city
        zip
        label
        value
        lat
        lng
      }
      specs {
        title
        list {
          key
          value
        }
      }
      photos {
        asset {
          metadata {
            dimensions {
              aspectRatio
            }
          }
          wide: fluid(toFormat: JPG, maxWidth: 945, maxHeight: 590) {
            ...GatsbySanityImageFluid
          }
          portrait: fluid(toFormat: JPG, maxWidth: 441, maxHeight: 590) {
            ...GatsbySanityImageFluid
          }
          square: fluid(toFormat: JPG, maxWidth: 590, maxHeight: 590) {
            ...GatsbySanityImageFluid
          }
          small: fluid(toFormat: JPG, maxWidth: 320, maxHeight: 204) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    estatePage: sanityPage(_id: { eq: "estate" }) {
      contact {
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
    }
  }
`;

function Chevron() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11">
      <path d="M15.65013083.936404l1.36745234 1.459478L8.833857 10.063596.6501308 2.395882 2.0175832.936404 8.833857 7.322889z" />
    </svg>
  );
}

const StyledAccordionItemTitle = styled(AccordionItemTitle)`
  background-color: ${th('white')};
  cursor: pointer;
  &:focus {
    outline: none;
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  &[aria-selected='false'] svg {
    fill: ${th('primary')};
  }
  &[aria-selected='true'] svg {
    transform: scaleY(-1);
    fill: ${th('orange')};
  }
`;

const Property = ({ data: { property, estatePage } }) => {
  /**
   * * Sliders
   * @mainSlider DOMNode for main slider
   * @secondarySlider DOMNode for secondary slider
   * @slidersIndex slider's current active index
   */
  const mainSlider = useRef(null);
  const secondarySlider = useRef(null);
  const [slidersIndex, setSlidersIndex] = useState(0);

  const goToSlide = index => {
    mainSlider.current && mainSlider.current.slickGoTo(index);
    secondarySlider.current && secondarySlider.current.slickGoTo(index);
  };

  const imgStyles = {
    boxShadow:
      '0 32px 32px 0 rgba(0,8,8,0.20), 0 16px 16px 0 rgba(0,113,107,0.20), 0 8px 8px 0 rgba(0,113,107,0.20), 0 4px 4px 0 rgba(0,113,107,0.20), 0 2px 2px 0 rgba(0,113,107,0.20)'
  };


  const isSold = property.status === 'sold';

  return (
    <>
      <SEO
        title={property.title}
        description={property.shortDescription}
        image={property.photos[0].asset.wide.src}
        meta={isSold && {
          property: 'robots',
          content: 'noindex'
        }}
      />
      <Layout>
        <Grid maxWidth={1200} gutter={0}>
          <Row gutter={0}>
            <Col md={10} gutter={0}>
              <Box position="relative">
                <Slider
                  ref={mainSlider}
                  asNavFor={secondarySlider.current}
                  className={'carousel--property'}
                  nextArrow={<NextArrow />}
                  prevArrow={<PreviousArrow />}
                  fade={true}
                  draggable={false}
                  swipeToSlide={false}
                  afterChange={(current, next) => {
                    setSlidersIndex(current);
                  }}
                >
                  {property.photos.map((photo, i) => (
                    <Box
                      index={i}
                      key={i}
                      pl={{ xs: '20px', lg: '30px' }}
                      pr={{ xs: '20px', md: '30px' }}
                      pb={{ xs: 6, md: 8 }}
                    >
                      <Box backgroundColor="white" style={imgStyles}>
                        {photo.asset.metadata.dimensions.aspectRatio >= 1.33 && (
                          <Img fluid={photo.asset.wide} />
                        )}
                        {photo.asset.metadata.dimensions.aspectRatio === 1 && (
                          <Img
                            fluid={photo.asset.square}
                            style={{
                              height: '100%',
                              width: '62.5%',
                              margin: '0 auto'
                            }}
                          />
                        )}
                        {photo.asset.metadata.dimensions.aspectRatio < 1.33 &&
                          photo.asset.metadata.dimensions.aspectRatio !== 1 && (
                            <Img
                              fluid={photo.asset.portrait}
                              style={{
                                height: '100%',
                                width: '46.7%',
                                margin: '0 auto'
                              }}
                            />
                          )}
                      </Box>
                    </Box>
                  ))}
                </Slider>
              </Box>
            </Col>
            <Col gutter={0} pl={{ xs: '12px', md: 0 }} pr={{ xs: '12px', lg: '30px' }} md={2}>
              {property.photos.length > 4 ? (
                <Slider
                  asNavFor={mainSlider.current}
                  ref={secondarySlider}
                  slidesToShow={4}
                  slidesToScroll={1}
                  vertical={true}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  arrows={false}
                  responsive={[
                    {
                      breakpoint: 768,
                      settings: {
                        vertical: false
                      }
                    }
                  ]}
                >
                  {property.photos.map((photo, i) => (
                    <Box
                      onClick={() => goToSlide(i)}
                      key={i}
                      px={{ xs: 1, md: 0 }}
                      mb={{ xs: 0, md: 3, lg: 4 }}
                    >
                      <Img fluid={photo.asset.small} />
                    </Box>
                  ))}
                </Slider>
              ) : (
                <Row
                  gutter={'10px'}
                  flexDirection={{ xs: 'row', md: 'column' }}
                  flexWrap={{ md: 'nowrap' }}
                >
                  {property.photos.map((photo, i) => (
                    <Col gutter={'10px'} key={i}>
                      <Box mb={{ md: 3, lg: 4 }} onClick={() => goToSlide(i)}>
                        <Img fluid={photo.asset.small} />
                      </Box>
                    </Col>
                  ))}
                </Row>
              )}

              <Box
                display="flex"
                justifyContent={{ xs: 'center', md: 'space-between' }}
                mt={{ xs: 2, md: 'auto' }}
                mb={{ xs: 4, md: 8 }}
                width={1}
              >
                <Box
                  display={{ xs: 'none', md: 'block' }}
                  className="button"
                  onClick={() => goToSlide(slidersIndex - 1)}
                  style={{ cursor: 'pointer' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="24"
                    viewBox="0 0 17 24"
                  >
                    <g fill="#00716B">
                      <path d="M9.5 23.5h-2v-22h2z" />
                      <path d="M1.83675279 9.5L.5 8.0608612 8.5.5l8 7.5608612L15.1632472 9.5 8.5 3.2025141z" />
                    </g>
                  </svg>
                </Box>
                <p>
                  {slidersIndex + 1} / {property.photos.length}
                </p>
                <Box
                  display={{ xs: 'none', md: 'block' }}
                  className="button"
                  onClick={() => goToSlide(slidersIndex + 1)}
                  style={{ cursor: 'pointer' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="24"
                    viewBox="0 0 17 24"
                    style={{ transform: 'scaleY(-1)' }}
                  >
                    <g fill="#00716B">
                      <path d="M9.5 23.5h-2v-22h2z" />
                      <path d="M1.83675279 9.5L.5 8.0608612 8.5.5l8 7.5608612L15.1632472 9.5 8.5 3.2025141z" />
                    </g>
                  </svg>
                </Box>
              </Box>
            </Col>
          </Row>
        </Grid>
        <Grid mb={8}>
          <Row alignItems="flex-end">
            <Col xs={12} md={4} mb={{ xs: 4, md: 0 }}>
              <Box pb={1} borderBottom="1px solid">
                <Typography opacity={0.5}>
                  {property.transaction === 'forSale' && 'Te koop'}
                  {property.transaction === 'forRent' && 'Te huur'}
                </Typography>
                <Typography
                  variant="h2"
                  fontFamily={theme.fontPrimary}
                  fontSize={4}
                  fontWeight="bold"
                >
                  € {numberWithCommas(property.price)}
                </Typography>
              </Box>
            </Col>
            <Col lg={2} olg={1}>
              <Box pb={2} borderBottom="1px solid">
                <Typography>{property.type.title}</Typography>
              </Box>
            </Col>
            <Col lg={4} olg={1}>
              <Box pb={2} borderBottom="1px solid">
                <Typography>
                  {property.location.zip}, {property.location.city}
                </Typography>
              </Box>
            </Col>
          </Row>
        </Grid>
        <Grid mb={8}>
          <Row>
            <Col xs={12} md={7} mb={{ xs: 6, md: 0 }}>
              <Box mb={6}>
                <Typography variant="h1" fontSize={4} mb={4}>
                  {property.title}
                </Typography>

                {property.description &&
                  property.description.map((item, i) => (
                    <Typography key={i} as="p" mt={i !== 0 ? 1 : 0}>
                      {item.children[0].text}
                    </Typography>
                  ))}
              </Box>
              <Map property={property} />
            </Col>
            <Col md={4} omd={1}>
              <Box
                style={{
                  overflowY: 'auto',
                  boxShadow:
                    '0 32px 32px 0 rgba(0,8,8,0.20), 0 16px 16px 0 rgba(0,113,107,0.20), 0 8px 8px 0 rgba(0,113,107,0.20), 0 4px 4px 0 rgba(0,113,107,0.20), 0 2px 2px 0 rgba(0,113,107,0.20)'
                }}
                backgroundColor="white"
                height={{ md: 700 }}
                maxHeight={{ md: 'calc(100% - 33px)' }}
              >
                {property && property.specs && property.specs[0].list.length ? (
                  <Accordion>
                    {property.specs.map(
                      (spec, i) =>
                        spec.list && (
                          <AccordionItem key={i} expanded={i === 0}>
                            <StyledAccordionItemTitle>
                              <Box
                                py={2}
                                px={'20px'}
                                borderBottom="1px solid"
                                borderColor="green"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography fontSize={3} color="brick" fontWeight="bold">
                                  {spec.title}
                                </Typography>
                                <Chevron />
                              </Box>
                            </StyledAccordionItemTitle>
                            <AccordionItemBody>
                              {spec.list &&
                                spec.list.map((list, i) => (
                                  <Box
                                    key={i}
                                    py={2}
                                    px={'20px'}
                                    backgroundColor="white"
                                    borderBottom="1px solid"
                                    borderColor="green"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Typography>{list.key}</Typography>
                                    <Typography textAlign="right">{list.value}</Typography>
                                  </Box>
                                ))}
                            </AccordionItemBody>
                          </AccordionItem>
                        )
                    )}
                  </Accordion>
                ) : null}
              </Box>
            </Col>
          </Row>
        </Grid>
        <Grid mb={{ xs: 6, md: 18 }}>
          <Row>
            <Col xs={12} md={6} mb={{ xs: 2, md: 0 }}>
              <Box
                py={{ xs: 4, md: 5, xl: 7 }}
                px={{ xs: 4, xl: 6 }}
                height={1}
                backgroundColor="primary"
                textAlign="center"
              >
                <Typography variant="h2" color="green" fontSize={{ xs: 3, lg: 5 }} mb={3}>
                  Ontdek hoeveel u voor uw woning kunt lenen.
                </Typography>
                <Button as={Link} to="/kredieten" color="green" brdrColor="green20" accent="brick">
                  Meer over kredieten
                </Button>
              </Box>
            </Col>
            <Col xs={12} md={6}>
              <Box
                py={{ xs: 4, md: 5, xl: 7 }}
                px={{ xs: 4, xl: 6 }}
                height={1}
                backgroundColor="primary"
                textAlign="center"
              >
                <Typography variant="h2" color="green" fontSize={{ xs: 3, lg: 5 }} mb={3}>
                  Vermijd dat een ongelukje grote financiële gevolgen heeft.
                </Typography>
                <Button
                  as={Link}
                  to="/verzekeringen"
                  color="green"
                  brdrColor="green20"
                  accent="brick"
                >
                  Meer over de bescherming van uw woning
                </Button>
              </Box>
            </Col>
          </Row>
        </Grid>
        <Contact
          formName={property.title}
          title={'Interesse in dit pand? Neem contact met ons op.'}
          image={estatePage.contact.image.asset.fixed.src}
        />
      </Layout>
    </>
  );
};

export default Property;
