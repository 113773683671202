import React from 'react';

export const NextArrow = ({ className, style, onClick }) => {
  return (
    <svg
      className={className}
      style={{
        ...style,
        top: 'calc(50% - 30px)',
        display: 'block',
        right: '60px',
        zIndex: 1,
        width: '25px'
      }}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="23"
      viewBox="0 0 31 23"
    >
      <defs>
        <path
          id="next_b"
          d="M18.7197994 6.5L13.2702613.68372617l1.4594774-1.36745234L22.3974532 7.5l-7.6677145 8.1837262-1.4594774-1.3674524L18.7197994 8.5H-1v-2h19.7197994z"
        />
        <filter
          id="next_a"
          width="159.8%"
          height="185.5%"
          x="-29.9%"
          y="-30.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
      </defs>
      <g fill="none" transform="translate(5 2)">
        <use fill="#000" filter="url(#next_a)" xlinkHref="#next_b" />
        <use fill="#FFF" xlinkHref="#next_b" />
      </g>
    </svg>
  );
};

export const PreviousArrow = ({ className, style, onClick }) => {
  return (
    <svg
      className={className}
      style={{
        ...style,
        top: 'calc(50% - 30px)',
        display: 'block',
        left: '60px',
        zIndex: 1,
        width: '25px'
      }}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="23"
      viewBox="0 0 31 23"
    >
      <defs>
        <path
          id="prev_b"
          d="M18.7197994 6.5L13.2702613.68372617l1.4594774-1.36745234L22.3974532 7.5l-7.6677145 8.1837262-1.4594774-1.3674524L18.7197994 8.5H-1v-2h19.7197994z"
        />
        <filter
          id="prev_a"
          width="159.8%"
          height="185.5%"
          x="-29.9%"
          y="-30.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
      </defs>
      <g fill="none" transform="matrix(-1 0 0 1 26.397 2)">
        <use fill="#000" filter="url(#prev_a)" xlinkHref="#prev_b" />
        <use fill="#FFF" xlinkHref="#prev_b" />
      </g>
    </svg>
  );
};
