import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@smooth-ui/core-sc';

//* Mapbox
import MapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapStyle from 'styles/mapbox';

const MapBox = ({ property }) => {
  /**
   * * Mapbox
   * @mapSettings state: holds default map settings (lat/long set on Brussels)
   * @address state: lat/long for the property's address
   * @goToMarker func: center marker on Map
   */
  const [mapSettings, setMapSettings] = useState({
    latitude: 50.8465573,
    longitude: 4.351697,
    zoom: 15
  });

  const [address, setAddress] = useState({
    latitude: property.location.lat,
    longitude: property.location.lng
  });

  const goToMarker = () => {
    setMapSettings({
      longitude: address.longitude,
      latitude: address.latitude,
      zoom: 15
    });
  };

  /**
   * * Fetch Mapbox Geocoding API on load once
   * * using the property's street, number, zip code and city
   * * to retrieve it's coordinates.
   */
  useEffect(() => {
    const { street, number, location } = property;

    const fetchData = async () => {
      const address = `${street} ${number} ${location.zip} ${location.city}`;
      const mapboxGeocodingURI = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}}.json?access_token=${
        process.env.GATSBY_MAPBOX_ACCESS_TOKEN
      }&country=be&types=address&limit=1`;
      const data = await fetch(mapboxGeocodingURI);
      const json = await data.json();

      const coordinates = json.features[0].geometry.coordinates;
      setAddress({ longitude: coordinates[0], latitude: coordinates[1] });
      setMapSettings({ latitude: coordinates[1], longitude: coordinates[0], zoom: 15 });
    };

    fetchData();
  }, [property]);
  return (
    <>
      <Box mb={1}>
        <MapGL
          width="100%"
          height={400}
          latitude={mapSettings.latitude}
          longitude={mapSettings.longitude}
          zoom={mapSettings.zoom}
          onViewportChange={viewport =>
            setMapSettings({
              latitude: viewport.latitude,
              longitude: viewport.longitude,
              zoom: viewport.zoom
            })
          }
          attributionControl={false}
          style={{ maxWidth: '100%' }}
          mapStyle={mapStyle}
          mapboxApiAccessToken={process.env.GATSBY_MAPBOX_ACCESS_TOKEN}
        >
          <Marker
            latitude={address.latitude}
            longitude={address.longitude}
            offsetLeft={-10}
            offsetTop={-10}
          >
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                `${property.street} ${property.number} ${property.location.zip} ${
                  property.location.city
                }`
              )}`}
              width={20}
              height={20}
              backgroundColor="red"
              borderRadius="50%"
              display="block"
            />
          </Marker>
        </MapGL>
      </Box>

      <Typography style={{ cursor: 'pointer' }} onClick={goToMarker} opacity={0.5}>
        {property.street} {property.number}, {property.location.zip} {property.location.city}
      </Typography>
    </>
  );
};

export default MapBox;
